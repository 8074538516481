import React, { useMemo, useCallback } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { sortBy } from 'lodash';
import { useThunkDispatch } from '@common/hooks';

import { FormQuestions } from '@modules/forms/containers/edit-form/form-questions';
import { Api } from '@common/services/api';
import { AlertService } from '@common/services/alert';
import { Screen, ScreenComponentItem, ScreenItem } from '@modules/learning/types/objects';
import { useIsAvailableInPlanPackage } from '@common/hooks/use-is-available-in-plan-package';
import createTempId from '@common/utils/create-temp-id';
import swap from '@common/utils/swap';
import { transformScreenFromAPI } from '@modules/survey/utils';
import type { FormDetail } from '@modules/forms/types';
import { EComponentTypes } from '@modules/learning/definitions';
import { EPlanPackageConfig } from '@common/definitions';

import '@modules/forms/components/submissions/submission.scss';

export default function ModeratorFormContent(props: any) {
  const { match, selectedForm, organisationId, setForm } = props;
  const { params } = match;
  const { networkId, screenId } = params;
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useThunkDispatch();

  const {
    isAvailable: canCreateFileUploadQuestion, showUpgradeModal,
  } = useIsAvailableInPlanPackage(EPlanPackageConfig.FORMS_SPECIAL_INPUT);

  const updateComponentsState = useCallback((
    sId: string,
    updateFn: (components: ScreenComponentItem[]) => ScreenComponentItem[],
  ) => {
    setForm((previousForm: FormDetail) => previousForm && ({
      ...previousForm,
      screens: previousForm.screens.map((s) => {
        if (s.id === sId) {
          return {
            ...s,
            components: updateFn(s.components),
          };
        }
        return s;
      })
    }));
  }, [setForm]);

  const formId = params?.id;
  const fns = useMemo(() => ({
    openQuestion: (id: string) => {
      history.push(`/networks/${networkId}/forms/${formId}/edit/${id}`);
    },
    createQuestion: async (question: ScreenComponentItem) => {
      if (!canCreateFileUploadQuestion && question.type === EComponentTypes.QUESTION_FILE_UPLOAD) {
        showUpgradeModal();
      } else {
        const payload = question ? { components: [question] } : {};
        const res = await Api.post<{ data: Screen }>(
          `/v1/organisations/${organisationId}/forms/${formId}/screens`, payload,
        );
        const screen = transformScreenFromAPI(res.data);
        setForm((previousForm: FormDetail) => previousForm && ({
          ...previousForm,
          screens: [...previousForm.screens, screen],
        }));
        dispatch({
          type: 'forms/CREATE_QUESTION',
          formId,
          screen,
        });
        history.push(`/networks/${networkId}/forms/${formId}/edit/${screen.id}`);
      }
    },
    deleteQuestion: async (id: string) => {
      await Api.delete(`/v1/organisations/${organisationId}/forms/${formId}/screens/${id}`);
      setForm((previousForm: FormDetail) => previousForm && ({
        ...previousForm,
        screens: (previousForm as FormDetail).screens.filter((s) => s.id !== id),
      }));
      if (id === screenId) {
        const currentIndex = selectedForm?.screen_ids?.indexOf(id) || 0;
        const newScreenId = selectedForm?.screen_ids?.[currentIndex ? currentIndex - 1 : 1];
        history.push(`/networks/${networkId}/forms/${formId}/edit/${newScreenId}`);
      }
    },
    duplicateQuestion: async ({ id }: ScreenItem) => {
      const res = await Api.post<{ data: Screen }>(
        `/v1/organisations/${organisationId}/forms/${formId}/screens/${id}/duplicate`
      );
      const screen = transformScreenFromAPI(res.data);
      setForm((previousForm: FormDetail) => previousForm && ({
        ...previousForm,
        screens: [...previousForm.screens, screen],
      }));
      dispatch({
        type: 'forms/DUPLICATE_SCREEN',
        duplicatedFromScreenId: id,
        screen,
        formId,
      });
      history.push(`/networks/${networkId}/forms/${formId}/edit/${screen.id}`);
    },
    orderQuestions: (id: string, index: number) => {
      if (!selectedForm || !selectedForm.screens) return;
      const order = sortBy(selectedForm.screens, ['index']).map((screen) => screen.id);
      const newOrder = swap(order, index, order.indexOf(id));
      setForm((previousForm: FormDetail) => previousForm && ({
        ...previousForm,
        screens: newOrder.map((sId, sIndex) => ({
          ...previousForm.screens.find((s) => s.id === sId),
          index: sIndex,
        }) as Screen),
      }));
    },
    saveOrderQuestions: async (order: string[]) => {
      if (selectedForm) {
        try {
          await Api.put(
            `/v1/organisations/${organisationId}/forms/${formId}/screens`,
            { screens: order },
          );
          AlertService.success(t('survey:survey_order_alert_success'));
        } catch (e: any) {
          if (e && typeof e.status_code === 'number') {
            AlertService.forStatus(e.status_code, {
              warning: t('survey:survey_order_alert_warning'),
              error: t('survey:survey_order_alert_error'),
            });
          }
        }
      }
    },
    addComponent: ({ component, id: _id }: { component: ScreenComponentItem; id: string }) => {
      updateComponentsState(_id, (components) => ([
        ...components,
        { ...component, id: createTempId() },
      ]));
    },
    deleteComponent: (_id: string, componentId: any) => {
      updateComponentsState(_id, (components) => components.filter(
        (c) => c.id !== componentId
      ));
    },
    updateComponent: (_id: string, component: ScreenComponentItem) => {
      if (component) {
        updateComponentsState(_id, (components) => components.map((c) => (
          c.id === component.id ? { ...component } : c)
        ));
      }
    },
    updateOrderComponents: (_id: string, items: string[]) => {
      updateComponentsState(_id, (components) => items.map((itemId, index) => ({
        ...components.find(({ id }) => id === itemId),
        index,
      }) as ScreenComponentItem));
    },
  }), [organisationId, selectedForm, setForm]);

  return (
    <FormQuestions
      {...props} {...fns}
      form={selectedForm}
      screen={(selectedForm as FormDetail).screens.find((s) => s.id === screenId)}
      previewScreens={selectedForm.screens}
    />
  );
}
